@import "fonts/Analogue/Other\ Font\ Files/stylesheet.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-section {
  padding: 1.5em 0; 
}

@media (min-width: 768px) {
  .site-section {
    padding: 1.5em 0; 
  } 
}

.site-section.site-section-sm {
  padding: 4em 0; 
}

/* Section Titles */
.site-section-heading {
  color: white;
  position: relative;
  font-size: 2rem; 
}

@media (min-width: 768px) {
  .site-section-heading {
    font-size: 2rem; 
  } 
}

.site-section-heading:after {
  content: "";
  left: 0%;
  bottom: 0;
  position: absolute;
  width: 100px;
  height: 1px;
  background: #000; 
}

.site-section-heading.text-center:after {
  content: "";
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  left: 50%;
  bottom: 0;
  position: absolute;
  width: 100px;
  height: 1px;
  background: #000; 
}

.img-fluid {
  max-width: 100%;
  height: auto; 
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; 
}

.icon-wrap {
  top: 30%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.icon-wrap :hover {
  color: rgb(20, 122, 238);
  background-color: rgb(255, 255, 255);
}

.modal-body {
  position:relative;
  padding:0px;
}
.close {
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}