/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 12, 2020 */



@font-face {
    font-family: 'analogueitalic';
    src: url('analogue-italic-webfont.woff2') format('woff2'),
         url('analogue-italic-webfont.woff') format('woff'),
         url('analogue-italic-webfont.ttf') format('truetype'),
         url('analogue-italic-webfont.svg#analogueitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'analogueregular';
    src: url('analogue-webfont.woff2') format('woff2'),
         url('analogue-webfont.woff') format('woff'),
         url('analogue-webfont.ttf') format('truetype'),
         url('analogue-webfont.svg#analogueregular') format('svg');
    font-weight: normal;
    font-style: normal;

}